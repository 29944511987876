import Vue from 'vue'
import App from './App.vue'

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";

// import devtools from '@vue/devtools'
// // Vue-Devtools Standallone Connection
// if (process.env.NODE_ENV === 'development') {
//   devtools.connect('http://localhost', 8098)
// }

Vue.use(BootstrapVue);

// App Init

new Vue({
  render: h => h(App),
}).$mount('#app')