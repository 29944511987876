<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary" style="position: fixed; width:100%; z-index: 100;">
    <div class="container">
    <a class="navbar-brand d-flex" href="#">
      <Logo :fill="'#fff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
      Dateivergleich
    </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
       
      </div>  
    </div>
  </nav>
</template>
<script>

import Logo from '../assets/Logo'
export default {
  components: {
    Logo,
  },
};
</script>
