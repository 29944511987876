<template>
  <div>
    <div style="padding-top: 10px;">
      <b-container class="bv-example-row">
        <b-row v-if="Boolean(badAlert)">
          <b-col>
            <b-alert
            class="mt-4 mb-4"
            variant="danger"
            dismissible
            fade
            :show="Boolean(badAlert)"
            @dismissed="badAlert=''"
          >{{badAlert}}</b-alert>
          </b-col>
        </b-row>
        <b-row v-if="!Boolean(check)">
          <b-col>
            <span>Apple</span>
            <b-form-file
              v-model="apple"
              :state="Boolean(apple)"
              placeholder="eine Datei auswählen oder hier rein ziehen..."
              drop-placeholder="Hier ablegen..."
            ></b-form-file>
          </b-col>
          <b-col>
            <span>ComLine</span>
            <b-form-file
              v-model="comline"
              :state="Boolean(comline)"
              placeholder="eine Datei auswählen oder hier rein ziehen..."
              drop-placeholder="Hier ablegen..."
            ></b-form-file>
          </b-col>
        </b-row>
        <div v-if="Boolean(Fails)">
          <b-row>
            <b-col v-for=" (value, name, index) in Fails" :key="index">
              <h2>{{name}}</h2>

              <b-card
                class="mt-2"
                :border-variant="checkEmpty(value.kundennummer)"
                header="Kundennummer"
                align="center"
              >
                <table v-if="value.kundennummer.length > 0" class="table">
                  <tr>
                    <th>Kunden-ID</th>
                  </tr>
                  <tr v-for="item in value.kundennummer" :key="item.id">
                    <td>{{item.kundenid}}</td>
                  </tr>
                </table>
                <div v-else>
                  <b-alert show variant="light" class="text-center" style="width:100%;">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                      <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                      <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg><br>
                    Keine Abweichungen gefunden.
                  </b-alert>
                </div>
              </b-card>   

              <b-card
                class="mt-2"
                :border-variant="checkEmpty(value.herstellernummer)"
                header="Herstellernummer"
                align="center"
              >
                <table v-if="value.herstellernummer.length > 0"  class="table">
                  <tr>
                    <th>Kunden-ID</th>
                    <th>Herstellernr.</th>
                  </tr>
                  <tr v-for="item in value.herstellernummer" :key="item.id">
                    <td>{{item.kundenid}}</td>
                    <td>{{item.herstellernummer}}</td>
                  </tr>
                </table>
                <div v-else>
                  <b-alert show variant="light" class="text-center" style="width:100%;">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                      <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                      <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg><br>
                    Keine Abweichungen gefunden.
                  </b-alert>
                </div>
              </b-card>   

              <b-card
                class="mt-2 mb-3"
                :border-variant="checkEmpty(value.menge)"
                header="Menge"
                align="center"
              >
                <table v-if="value.menge.length > 0" class="table">
                  <tr>
                    <th>Kunden-ID</th>
                    <th>Herstellernr.</th>
                    <th>Menge</th>
                  </tr>
                  <tr v-for="item in value.menge" :key="item.id">
                    <td>{{item.kundenid}}</td>
                    <td>{{item.herstellernummer}}</td>
                    <td>{{item.menge}}</td>
                  </tr>
                </table>
                <div v-else>
                  <b-alert show variant="light" class="text-center" style="width:100%;">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                      <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                      <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg><br>
                    Keine Abweichungen gefunden.
                  </b-alert>
                </div>
              </b-card>
              
            </b-col>
          </b-row>
        </div>
        <div v-if="Boolean(NoFails)">
          <b-alert show variant="light" class="text-center" style="width:100%;">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
              <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
            </svg>
            Keine Abweichungen gefunden.
          </b-alert>
        </div>
        <div v-if="Boolean(badAlert)">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
            <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
          </svg>
        </div>
        <b-row class="mt-3" v-if="!Boolean(check)">
          <b-col><b-btn class="bg-primary border-0" block variant="success" :disabled="!Boolean(apple && comline)" @click="loadData">Vergleichen</b-btn></b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        apple: undefined,
        comline: undefined,
        badAlert: "",
        check: undefined,
        Fails: undefined,
        NoFails: undefined
      }
    },
    methods: {
      async loadData() {
        if(Boolean(this.apple) || Boolean(this.comline)){
          this.badAlert = ''
          var ComLineData = await this.parseMe(this.comline)
          var AppleData = await this.parseMe(this.apple)

          var Fails = this.checkData(AppleData, ComLineData)
          if (Fails === true){
            this.badAlert = "Es ist ein Fehler Aufgetreten. Prüfe deine Dateien."
          } else if (Fails !== "keine Fehler"){
            this.Fails = Fails
            this.check = true
          }else{
            this.NoFails = true
            this.check = true
          }
        } else {
          this.badAlert = 'Bitte beide felder füllen.'
        }
      },
      parseMe: async(input) => {
        var reader = new FileReader()
        return new Promise((resolve, reject) => {

          reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Es ist ein Problem aufgetreten."));
          };

          reader.onload = (e) => {
            var splitted = e.target.result.split('\n')
            var arr=[]
            splitted.forEach(element => {
              var temp = element.split(';')
              arr.push(temp)
              return null
            });
            resolve(arr)
          };
        
          reader.readAsText(input)

          reader.onerror = reject;
 
        })
      },
      checkData: (apple, cl) => {
        var badAlert = false
        var newApple = {}
        
        var kundenid = 0
        var herstellernummer = ""
        var menge = 0
        var retoureMenge = 0
        
        apple.forEach(row => {
          try {
            kundenid = row[2]
            herstellernummer = row[8]
            menge = parseInt(row[9])
          } catch {
            badAlert = true
          }

          if(kundenid === ""){
            kundenid = 595046
          }

          if(newApple[kundenid] === undefined || newApple[kundenid] === ""){
            newApple[kundenid]={}
            newApple[kundenid][herstellernummer] = {"menge": menge}
          }else 
          if(newApple[kundenid][herstellernummer] === undefined){
            newApple[kundenid][herstellernummer] = {"menge": menge}
          }else{
            newApple[kundenid][herstellernummer].menge = newApple[kundenid][herstellernummer].menge + menge
          }
          
        });
        console.log(newApple)   

        var newComline = {}

        cl.forEach(row => {
          try {
            kundenid = row[2]
            herstellernummer = row[13].split("-")[0]
            menge = parseInt(row[15])
            retoureMenge = parseInt(row[16])
          } catch {
            badAlert = true
          }

          menge = menge - retoureMenge

          if(kundenid === ""){
            kundenid = 595046
          }

          if(newComline[kundenid] === undefined || newComline[kundenid] === ""){
            newComline[kundenid]={}
            newComline[kundenid][herstellernummer] = {"menge": menge}
          }else 
          if(newComline[kundenid][herstellernummer] === undefined){
            newComline[kundenid][herstellernummer] = {"menge": menge}
          }else{
            newComline[kundenid][herstellernummer].menge = newComline[kundenid][herstellernummer].menge + menge
          }
          
        });
        console.log(newComline)


        var AppleFails = {"kundennummer":[], "herstellernummer": [], "menge":[]}
        var ComlineFails = {"kundennummer":[], "herstellernummer": [], "menge":[]}
        var fehler = false

        Object.keys(newComline).forEach(kundenid => {
          if(newApple[kundenid] === undefined){
            fehler = true
            AppleFails.kundennummer.push({"kundenid": kundenid, "herstellernummer": "FEHLT", "menge": "FEHLT"})
          }else{
            Object.keys(newComline[kundenid]).forEach(herstellernummer => {
              if(newApple[kundenid][herstellernummer] === undefined){
                fehler = true
                AppleFails.herstellernummer.push({"kundenid": kundenid, "herstellernummer": herstellernummer, "menge": "FEHLT"})
              }else
              if(newApple[kundenid][herstellernummer].menge !== newComline[kundenid][herstellernummer].menge){
                fehler = true
                AppleFails.menge.push({"kundenid": kundenid,"herstellernummer": herstellernummer, "menge": newApple[kundenid][herstellernummer].menge})
              }
            })
          }
        })

        Object.keys(newApple).forEach(kundenid => {
          if(newComline[kundenid] === undefined){
            fehler = true
            ComlineFails.kundennummer.push({"kundenid": kundenid, "herstellernummer": "FEHLT", "menge": "FEHLT"})
          }else{
            Object.keys(newApple[kundenid]).forEach(herstellernummer => {
              if(newComline[kundenid][herstellernummer] === undefined){
                fehler = true
                ComlineFails.herstellernummer.push({"kundenid": kundenid, "herstellernummer": herstellernummer, "menge": "FEHLT"})
              }else
              if(newComline[kundenid][herstellernummer].menge !== newApple[kundenid][herstellernummer].menge){
                fehler = true
                ComlineFails.menge.push({"kundenid": kundenid,"herstellernummer": herstellernummer, "menge": newComline[kundenid][herstellernummer].menge})
              }
            })
          }
        })
        var returning
        if (badAlert){
          returning = badAlert
        }else if( fehler)
        {
          returning = {"Apple": AppleFails, "ComLine": ComlineFails}
        }else {
          returning = "keine Fehler"
        }
        return returning
      },
      checkEmpty(value){
        var resp
        if(value.length === 0){
          resp = "success"
        }else {
          resp = "danger"
        }
        return (resp)
      }
    }
  };
</script>
<style scoped>
span {
  color: #373c42;
}
svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}
 .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
 
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
 
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
 
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
</style>