<template>
  <div>
    <Navbar />
    <main style="padding-top: 60px;">
      <Dashboard/>
    </main>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";

export default {
  components: {
    Navbar,
    Dashboard
  },
};
</script>
<style scoped>

</style>